import axios from "axios"
import { getBaseUrl } from "../utils"

const puja_detail = getBaseUrl() + "puja/pujalisting"
const puja_detail_by_id = getBaseUrl() + "puja/pujabyinstaid"
const puja_participate = getBaseUrl() + "puja/bookpuja"

export const pujaDetail_api = async (data) => {
    let config = {
        method: "post",
        url: puja_detail,
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response;
    }
}

export const pujaDetailById_api = async (data) => {
    let config = {
        method: "post",
        url: puja_detail_by_id,
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response;
    }
}

export const pujaParticipate_api = async (data) => {
    let config = {
        method: "post",
        url: puja_participate,
        data: data
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response;
    }
}