import React, { useEffect, useState } from 'react'
import "./puja.css"
import { pujaParticipate_api } from '../api/puja'

const PujaSubscription = ({ subscription, userId }) => {

    console.log(userId, "userId in the puja participate page of the astrosetu website")

    const [pujaParticipate, setPujaParticipate] = useState([])
    const [isLoading, SetisLoading] = useState(false)

    useEffect(() => {
        pujaParticipateFunc();
    }, [])
    const pujaParticipateFunc = async () => {
        SetisLoading(true)
        let temp = {
            puja_id: "",
            puja_date: "",
            puja_amount: "",
            puja_type: "",
            user_id: userId
        }
        try {
            let res = await pujaParticipate_api(temp)
            if (res.data.status) {
                setPujaParticipate(res.data.data)
                SetisLoading(false)
            }
        } catch (error) {
            SetisLoading(false)
            console.log("status false....")

        }
    }
    console.log(subscription, "subscription----------------------------------------------------------------------------------")
    return (
        <div>
            <section class="plans__container">
                <div class="plans">
                    <div class="plansHero">
                        <div class="planItem__container">
                            {subscription?.map((data, index) => (
                                <div class="planItem">
                                    <div class="cards">
                                        <div class="card__header">
                                            {/* <div class="card__icon symbol symbol--rounded"></div> */}
                                            <h2>{data?.packageType}</h2>
                                        </div>
                                        <div class="card__desc">{data?.packageDescription}</div>
                                    </div>
                                    <div style={{ paddingLeft: '1rem' }} class="price">Rs.{data?.packagePrice}<span>/ month</span></div>
                                    <ul class="featureList">
                                        <li><b>Package Name:</b>{data?.packageName}</li>
                                        <li class="disabled">Chat support</li>
                                        <li class="disabled">Mobile application</li>
                                        <li class="disabled">Unlimited users</li>
                                    </ul>
                                    <button class="button">Participate</button>
                                </div>
                            ))}


                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default PujaSubscription
