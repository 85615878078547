import React, { useEffect, useState } from "react";
import img1 from "../../images/banner2.png";
import s from "./puja.module.css";
import HOC from "../../Common/HOC";
import Carousel from "react-multi-carousel";
import { pujaDetailById_api, pujaDetail_api } from "../api/puja";
import { useLocation } from "react-router-dom";
import PujaFaqSection from "./PujaFaqSection";
import PujaSubscription from "./PujaSubscription";
import { useParams } from "react-router-dom";
const PujaDetail = () => {
  const location = useLocation();
  const slug = useParams();
  const id = location?.state?.data.id;
  const [pujaDetailById, setPujaDetailById] = useState({});
  const [isLoading, SetisLoading] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
    },
  };
  useEffect(() => {
    pujaDeatilFunc(slug.id);
  }, [slug]);

  const pujaDeatilFunc = async (data) => {
    SetisLoading(true);
    let temp = {
      instaId: data,
    };
    try {
      let res = await pujaDetailById_api(temp);
      if (res.data.status) {
        setPujaDetailById(res.data.data);
        SetisLoading(false);
      }
    } catch (error) {
      SetisLoading(false);
      console.log("status false....");
    }
  };
  return (
    <div className="puja_detail_page">
      <div className="container">
        <div className="puja_heading">
          <h3>Pooja</h3>
        </div>
        <div className="puja_image">
          <img className={s["puja_image_size"]} src={pujaDetailById?.pujaImage} />
        </div>
        <div className="puja_content">
          <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "#fd7e14" }}>
            <h3>About Puja</h3>
          </div>
          <div className="puja_about_content">{pujaDetailById?.aboutPuja}</div>
          <div style={{ display: "flex", width: "100%", alignItems: "center", padding: "1rem", color: "#fd7e14", flexDirection: "column" }}>
            <h5>About Temple</h5>
            <div>
              <h3>Temple Name:-{pujaDetailById?.mandirName}</h3>
            </div>
          </div>
          <div className="puja_about_content">{pujaDetailById?.aboutTempalDescription}</div>
        </div>
        <div className="puja_carousel">
          <div className={s["puja_temple_carousel"]}>
            <div style={{ paddingBlock: "1rem" }}>
              <div className={s["puja_temple_image_size"]}>
                <Carousel rewind={true} autoPlaySpeed={1000} autoPlay={true} responsive={responsive} slidesToSlide={1}>
                  {[0, 2, 3, 4, 5].map((data) => (
                    <img
                      src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                      style={{
                        display: "block",
                        height: "400px",
                        margin: "auto",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="puja_benefit_section">
          <div className="puja_benefit">
            <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "#fd7e14" }}>
              <h3>Benefits</h3>
            </div>
            <div className="benefit_point">
              <ul>
                {pujaDetailById.benifits?.map((data, index) => (
                  <p>
                    <b>
                      {index + 1}.{data.title}:-
                    </b>
                    {data.description}
                  </p>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="puja_faq_section">
          <div className="puja_faq_heading">
            <div style={{ display: "flex", width: "100%", justifyContent: "center", padding: "1rem", color: "#fd7e14" }}>
              <h3>FAQs</h3>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <PujaFaqSection faq={pujaDetailById.faq} />
            </div>
          </div>
        </div>
        <div className="puja_subscripion_section">
          <PujaSubscription userId={id} subscription={pujaDetailById?.packages} />
        </div>
      </div>
    </div>
  );
};

export default HOC(PujaDetail);
