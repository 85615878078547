import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';



const PujaFaqSection = ({ faq }) => {

    const [expanded, setExpanded] = React.useState(false);

    // const handleExpansion = () => {
    //     setExpanded((prevExpanded) => !prevExpanded);
    // };

    const handleExpansion = (panel) => (event, isExpanded) => {
        console.log(panel, "panel vaule in the accodian")
        setExpanded(isExpanded ? panel : false);
    };

    console.log(faq, "faq in the accordian page of the puja ")
    return (
        <div>
            <div>
                {faq?.map((data, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index + 1}`}
                        onChange={handleExpansion(`panel${index + 1}`)}
                        TransitionComponent={Fade}
                        transitionProps={{ timeout: 400 }}
                        sx={{
                            '& .MuiAccordion-region': { height: expanded === `panel${index + 1}` ? 'auto' : 0 },
                            '& .MuiAccordionDetails-root': { display: expanded === `panel${index + 1}` ? 'block' : 'none' },
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index + 1}-content`}
                            id={`panel${index + 1}-header`}
                        >
                            <Typography>Ques.{index + 1}.{data?.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {data?.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))
                }
            </div>
        </div >
    )
}

export default PujaFaqSection;
